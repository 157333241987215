import { Module } from "vuex"
import { RootState } from "../index"

export interface ModalModule {

}

export const modalModule: Module<ModalModule, RootState> = {
    namespaced: true,
    state: () => ({

    }),
    getters: {
    },
    mutations: {
    },
    actions: {
    }
}
