
import { useWindowManager } from "@/libs/WindowManager";
import { computed, defineComponent, reactive, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();
    const state = reactive({
      isOpend: false,

      navigations: [
        {
          name: "About",
          to: "/",
        },
        {
          name: "Studio",
          to: "/studio",
        },
        {
          name: "Ventures",
          to: "/ventures",
        },
        {
          name: "Stories",
          to: "/stories",
        },
        {
          name: "Careers",
          to: "/careers",
        },
      ],
    });

    const onClick = (id: string) => {
      if (id == "menu") {
        state.isOpend = !state.isOpend;
      }
    };

    useRouter().afterEach(() => {
      if (useWindowManager().isMobile()) {
        state.isOpend = false;
      }
    });

    return {
      state,
      onClick,
      isSelected: (to: string) => useRoute().path == to,
      isWhiteHeader: computed(() => store.getters["global/isWhiteHeader"]),
      isBlurHeader: computed(() => useWindowManager().offsetTop() > 10),
    };
  },
});
