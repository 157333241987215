import { modalModule, ModalModule } from "./modules/modal";
import { globalModul, GlobalModule } from "./modules/global";
import { dataModule, DataModule } from "./modules/data";
import { createStore } from "vuex";

export interface RootState {
  modal: ModalModule;
  global: GlobalModule;
  data: DataModule;
}

export default createStore({
  modules: {
    modal: modalModule,
    global: globalModul,
    data: dataModule,
  },
  strict: false,
});
