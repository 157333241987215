
import { defineComponent } from "vue";
import { useWindowManager } from "./libs/WindowManager";
import ModalArea from "./ModalArea.vue";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

export default defineComponent({
  components: {
    ModalArea,
  },
  setup() {
    useWindowManager().init();
  },
});
