
import { defineComponent, onMounted, reactive } from "vue";

export default defineComponent({
  setup() {
    const state = reactive({
      navigations: [
        {
          name: "About",
          to: "/",
        },
        {
          name: "Studio",
          to: "/studio",
        },
        {
          name: "Ventures",
          to: "/ventures",
        },
        {
          name: "Stories",
          to: "/stories",
        },
        {
          name: "Careers",
          to: "/careers",
        },
      ],
    });

    onMounted(() => {});

    return {
      state,
    };
  },
});
