export interface BallOptions {
  x: number;
  y: number;
  dx: number;
  dy: number;
  r: number;
  color: string;
  label?: string;
  labelFont?: string;
  labelColor?: string;
  opacity?: number;
}

export class Ball {
  private options: BallOptions = {
    x: 0,
    y: 0,
    dx: 0,
    dy: 0,
    r: 0,
    color: "#000000",
  };
  public x: number;
  public y: number;
  public dx: number;
  public dy: number;
  public r: number;
  public opacity: number;

  private ball: HTMLDivElement | null = null;

  constructor(options?: BallOptions) {
    this.options = {
      ...this.options,
      ...options,
    };
    this.x = this.options.x;
    this.y = this.options.y;
    this.dx = this.options.dx;
    this.dy = this.options.dy;
    this.r = this.options.r;
    this.opacity = this.options.opacity ?? 1;
  }

  draw(element: HTMLElement) {
    if (this.ball == null) {
      this.ball = document.createElement("div");
      element.appendChild(this.ball);
    }

    this.ball.style.position = "absolute";
    this.ball.style.backgroundColor = this.options.color;
    this.ball.style.borderRadius = "50%";
    this.ball.style.opacity = `${this.opacity}`;
    this.ball.style.color = this.options.labelColor ?? "#000000";
    this.ball.style.fontFamily = '"Roobert", sans-serif';
    this.ball.style.fontWeight = "500";
    this.ball.style.fontSize = "18px";
    this.ball.style.display = "flex";
    this.ball.style.alignItems = "center";
    this.ball.style.justifyContent = "center";
    this.ball.innerText = this.options.label ?? "";
    this.ball.style.width = `${this.r * 2}px`;
    this.ball.style.height = `${this.r * 2}px`;
    this.ball.style.transform = "translate(-50%, -50%)";
    this.ball.style.left = `${this.x}px`;
    this.ball.style.top = `${this.y}px`;
  }

  update(world: World) {
    if (world.element) {
      this.x += this.dx;
      this.y += this.dy;
      this.dx += world.gravityX; //Gravity X -> Right
      this.dy -= world.gravityY; //Gravity Y -> Bottom

      if (this.x > world.element.clientWidth - this.r) {
        this.x = world.element.clientWidth - this.r;
        this.dx *= -1;
      } else if (this.x < this.r) {
        this.x = this.r;
        this.dx *= -1;
      }
      if (this.y > world.element.clientHeight - this.r) {
        this.y = world.element.clientHeight - this.r;
        this.dy *= -0.7;
      }

      this.draw(world.element);
    }
  }
}

export class World {
  private balls: Ball[] = [];
  private isPause: boolean = false;

  constructor(public element: HTMLElement, public gravityY: number = -0.09, public gravityX: number = 0) {
    element.childNodes.forEach((child) => {
      element.removeChild(child);
    });
  }

  addBall(ball: Ball) {
    this.balls.push(ball);
  }

  clearBall() {
    this.balls = [];
  }

  start() {
    if (this.element) {
      for (var ball of this.balls) {
        ball.update(this);
        for (var ball2 of this.balls) {
          if (ball !== ball2) {
            var collision = this.checkCollision(ball, ball2);
            if (collision.collision) {
              this.adjustPositions(ball, ball2, collision.depth);
              this.resolveCollision(ball, ball2);
            }
          }
        }
      }

      if (!this.isPause) {
        requestAnimationFrame(() => {
          this.start();
        });
      }
    }
  }

  pause() {
    this.isPause = true;
  }

  checkCollision(ballA: Ball, ballB: Ball) {
    var rSum = ballA.r + ballB.r;
    var dx = ballB.x - ballA.x;
    var dy = ballB.y - ballA.y;
    return { collision: rSum * rSum > dx * dx + dy * dy, depth: rSum - Math.sqrt(dx * dx + dy * dy) };
  }

  resolveCollision(ballA: Ball, ballB: Ball) {
    var relVel = [ballB.dx - ballA.dx, ballB.dy - ballA.dy];
    var norm = [ballB.x - ballA.x, ballB.y - ballA.y];
    var mag = Math.sqrt(norm[0] * norm[0] + norm[1] * norm[1]);
    norm = [norm[0] / mag, norm[1] / mag];

    var velAlongNorm = relVel[0] * norm[0] + relVel[1] * norm[1];
    if (velAlongNorm > 0) return;

    var bounce = 0;
    var j = -(1 + bounce) * velAlongNorm;
    j /= 1 / ballA.r + 1 / ballB.r;

    var impulse = [j * norm[0], j * norm[1]];
    ballA.dx -= (1 / ballA.r) * impulse[0];
    ballA.dy -= (1 / ballA.r) * impulse[1];
    ballB.dx += (1 / ballB.r) * impulse[0];
    ballB.dy += (1 / ballB.r) * impulse[1];
  }

  adjustPositions(ballA: Ball, ballB: Ball, depth: number) {
    const percent = 0.4;
    const slop = 0.02;

    var correction = (Math.max(depth - slop, 0) / (1 / ballA.r + 1 / ballB.r)) * percent;

    var norm = [ballB.x - ballA.x, ballB.y - ballA.y];
    var mag = Math.sqrt(norm[0] * norm[0] + norm[1] * norm[1]);
    norm = [norm[0] / mag, norm[1] / mag];
    const correctionX = correction * norm[0];
    const correctionY = correction * norm[1];
    ballA.x -= Math.abs((1 / ballA.r) * correctionX) > 0.3 ? (1 / ballA.r) * correctionX : 0;
    ballA.y -= Math.abs((1 / ballA.r) * correctionY) > 0.3 ? (1 / ballA.r) * correctionY : 0;
    ballB.x += Math.abs((1 / ballB.r) * correctionX) > 0.3 ? (1 / ballB.r) * correctionX : 0;
    ballB.y += Math.abs((1 / ballB.r) * correctionY) > 0.3 ? (1 / ballB.r) * correctionY : 0;
  }
}
