export const copyTextToClipboard = (text: string) => {
  const fallbackCopyTextToClipboard = (text: string) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      const msg = successful ? "successful" : "unsuccessful";
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  };

  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
};

export const fileUpload = (cb: (file: File, fileURL: string) => void, accept = "image/*") => {
  const input = document.createElement("input");
  input.id = "profile-images";
  input.type = "file";
  input.style.display = "none";

  if (accept) {
    input.accept = accept;
  }
  input.onclick = () => {
    document.body.onfocus = () => {
      if (input.value.length) {
        console.log("Files Loaded");
      } else {
        input.remove();
      }
      document.body.onfocus = null;
    };
  };
  input.addEventListener(
    "change",
    (ev: any) => {
      if (ev.target.files && ev.target.files.length > 0) {
        const file = ev.target.files[0];
        const fileURL = URL.createObjectURL(file);
        cb(file, fileURL);
      }

      input.remove();
    },
    false
  );
  document.body.appendChild(input);
  input.click();
};

export const detectInAppBrowser = (ua: string) => {
  ua = ua.toLowerCase().trim();
  const isIOS = ua.includes("iphone") || ua.includes("ipod") || ua.includes("ipad");
  const isAndroid = ua.includes("android");

  // iOS Chrome
  if (ua.includes("crios")) {
    return "is_chrome_ios";
  }

  // Facebook
  if (ua.includes("fbios") || ua.includes("fb_iab")) {
    return isIOS ? "is_facebook_ios" : isAndroid ? "is_facebook_android" : "is_facebook_unknown";
  }

  // Instagram
  if (ua.includes("instagram")) {
    return isIOS ? "is_instagram_ios" : isAndroid ? "is_instagram_android" : "is_instagram_unknown";
  }

  // LINE
  if (ua.includes(" line/")) {
    return isIOS ? "is_line_ios" : isAndroid ? "is_line_android" : "is_line_unknown";
  }

  // iOS Safari|Twitter|Slack|Discord|etc
  if (isIOS && /safari\/[0-9.]+$/.test(ua)) {
    return "maybe_safari_ios";
  }

  // Android Chrome|Twitter|Slack|Discord|etc
  if (isAndroid && ua.includes("chrome") && /safari\/[0-9.]+$/.test(ua)) {
    return "maybe_chrome_android";
  }

  return null;
};

export const hexToRGB = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result && result.length >= 3) {
    return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    };
  } else {
    return {
      r: 0,
      g: 0,
      b: 0,
    };
  }
};

export const transColor = (startColorHex: string, endColorHex: string, percent: number) => {
  const startRGB = hexToRGB(startColorHex);
  const endRGB = hexToRGB(endColorHex);
  const r = startRGB.r + (endRGB.r - startRGB.r) * percent;
  const g = startRGB.g + (endRGB.g - startRGB.g) * percent;
  const b = startRGB.b + (endRGB.b - startRGB.b) * percent;
  return `rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)})`;
};

export const getElementBound = (id: string, bottom: boolean = false) => {
  const element = document.getElementById(id);
  if (element) {
    return bottom ? element.offsetTop + element.clientHeight : element.offsetTop;
  }
  return -1;
};
