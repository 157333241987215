
import { useWindowManager } from "@/libs/WindowManager";
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { getElementBound, transColor } from "@/libs/Utils";

export default defineComponent({
  setup() {
    const windowManager = useWindowManager();
    const store = useStore();
    const topSection = ref<HTMLElement>();

    const state: any = reactive({
      backgroundColor: "#FFFFEC",
      stories: computed(() => {
        if (useStore().getters["data/stories"]) {
          return useStore().getters["data/stories"];
        }
        return [];
      }),
      // displayStories: computed(() => [...state.stories].sort(() => Math.random() - 0.5).slice(0, 3)),
      displayStories: computed(() => [...state.stories].slice(0, 3)),
    });
    const style = reactive({
      backgroundColor: computed(() => ({ backgroundColor: state.backgroundColor })),
    });

    const setBackgroundColor = (triggerPoint: number, effectRange: number, startColor: string, endColor: string) => {
      if (windowManager.offsetTop() < triggerPoint) {
        state.backgroundColor = startColor;
      } else if (triggerPoint <= windowManager.offsetTop() && windowManager.offsetTop() <= triggerPoint + effectRange) {
        const percent = (windowManager.offsetTop() - triggerPoint) / effectRange;
        if (0 <= percent && percent <= 1) {
          state.backgroundColor = transColor(startColor, endColor, percent);
        }
      } else {
        state.backgroundColor = endColor;
      }
    };

    watch(
      () => windowManager.offsetTop(),
      () => {
        setHeaderColor();
        setBackgroundColor(getElementBound("mission"), 400, "#FFFFEC", "#FFFFFF");
      }
    );

    const setHeaderColor = () => {
      if (topSection.value) {
        if (windowManager.offsetTop() <= topSection.value.clientHeight - 80 / 2) {
          store.dispatch("global/setWhiteHeader");
        } else {
          store.dispatch("global/clearWhiteHeader");
        }
      }
    };

    const onClick = (id: string) => {
      if (id == "goToWhat") {
        window.scrollTo({
          behavior: "smooth",
          top: getElementBound("what-we-do"),
        });
      }
    };

    onMounted(() => {
      setHeaderColor();
    });

    return {
      state,
      topSection,
      style,
      onClick,
    };
  },
});
