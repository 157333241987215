
import { getElementBound, transColor } from "@/libs/Utils";
import { useWindowManager } from "@/libs/WindowManager";
import { computed, defineComponent, reactive, watch } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    useStore().dispatch("global/clearWhiteHeader");
    const windowManager = useWindowManager();

    const state = reactive({
      currentOver: -1,
      backgroundColor: "#ffffec",
    });
    const style = reactive({
      backgroundColor: computed(() => ({ backgroundColor: state.backgroundColor })),
    });

    const setBackgroundColor = (triggerPoint: number, effectRange: number, startColor: string, endColor: string) => {
      if (windowManager.offsetTop() < triggerPoint) {
        state.backgroundColor = startColor;
      } else if (triggerPoint <= windowManager.offsetTop() && windowManager.offsetTop() <= triggerPoint + effectRange) {
        const percent = (windowManager.offsetTop() - triggerPoint) / effectRange;
        if (0 <= percent && percent <= 1) {
          state.backgroundColor = transColor(startColor, endColor, percent);
        }
      } else {
        state.backgroundColor = endColor;
      }
    };

    watch(
      () => windowManager.offsetTop(),
      () => {
        setBackgroundColor(getElementBound("top", true), 400, "#ffffec", "#FFFFFF");
      }
    );

    const onMouseEnter = (e: MouseEvent) => {
      if (e.target) {
        state.currentOver = Number((e.target as HTMLElement).dataset.id);
      }
    };

    const isOver = (id: number) => {
      return state.currentOver == id;
    };

    return {
      state,
      style,
      onMouseEnter,
      isOver,
    };
  },
});
