import { onMounted, onUnmounted, ref } from "vue";

export class WindowManager {
  private height = ref<number>(0);
  private width = ref<number>(0);
  private scroll = ref<number>(0);
  private bottom = ref<number>(0);

  private mobile = ref<boolean>(false);
  private tablet = ref<boolean>(false);
  private desktop = ref<boolean>(false);

  init() {
    const onResize = () => {
      this.height.value = window.innerHeight;
      this.width.value = window.innerWidth;
      this.mobile.value = window.innerWidth < 840;
      this.tablet.value = 840 <= window.innerWidth && window.innerWidth <= 1168;
      this.desktop.value = window.innerWidth > 1168;
    };

    const onScroll = () => {
      this.scroll.value = document.documentElement.scrollTop;
      this.bottom.value = window.document.body.scrollHeight - window.innerHeight - window.scrollY;
    };

    onMounted(() => {
      onResize();
      onScroll();
      window.addEventListener("resize", onResize);
      window.addEventListener("scroll", onScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", onResize);
      window.removeEventListener("scroll", onScroll);
    });
  }

  public getWindowHeight(): number {
    return this.height.value;
  }

  public getWindowWidth(): number {
    return this.width.value;
  }

  public isMobile(): boolean {
    return this.mobile.value;
  }

  public isTablet(): boolean {
    return this.tablet.value;
  }

  public isDesktop(): boolean {
    return this.desktop.value;
  }

  public offsetBottom(): number {
    return this.bottom.value;
  }

  public offsetTop(): number {
    return this.scroll.value;
  }
}

let windowManager: WindowManager | undefined = undefined;

export const useWindowManager = (): WindowManager => {
  if (windowManager) {
    return windowManager;
  } else {
    windowManager = new WindowManager();
    return windowManager;
  }
};
