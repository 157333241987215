
import CircleCollisions from "@/components/CircleCollisions.vue";
import { getElementBound, transColor } from "@/libs/Utils";
import { useWindowManager } from "@/libs/WindowManager";
import { computed, defineComponent, onMounted, reactive, watch } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    CircleCollisions,
  },
  setup() {
    useStore().dispatch("global/clearWhiteHeader");
    const windowManager = useWindowManager();

    const state = reactive({
      backgroundColor: "#fdf3f4",
    });

    const style = reactive({
      backgroundColor: computed(() => ({ backgroundColor: state.backgroundColor })),
      averageIRR: { clipPath: `inset(100% 0 0 0)` },
      exitRate: { clipPath: `inset(100% 0 0 0)` },
    });

    const setBackgroundColor = (triggerPoint: number, effectRange: number, startColor: string, endColor: string) => {
      if (windowManager.offsetTop() < triggerPoint) {
        state.backgroundColor = startColor;
      } else if (triggerPoint <= windowManager.offsetTop() && windowManager.offsetTop() <= triggerPoint + effectRange) {
        const percent = (windowManager.offsetTop() - triggerPoint) / effectRange;
        if (0 <= percent && percent <= 1) {
          state.backgroundColor = transColor(startColor, endColor, percent);
        }
      } else {
        state.backgroundColor = endColor;
      }
    };

    watch(
      () => windowManager.offsetTop(),
      () => {
        setBackgroundColor(getElementBound("performance"), 400, "#fdf3f4", "#FFFFFF");
        if (windowManager.offsetTop() >= getElementBound("performance")) {
          style.averageIRR = { clipPath: `inset(47% 0 0 0)` };
          style.exitRate = { clipPath: `inset(45% 0 0 0)` };
        }
      }
    );

    return {
      state,
      style,
    };
  },
});
