
import { getElementBound, transColor } from "@/libs/Utils";
import { useWindowManager } from "@/libs/WindowManager";
import { computed, defineComponent, onMounted, reactive, watch } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    useStore().dispatch("global/clearWhiteHeader");
    const windowManager = useWindowManager();
    const state = reactive({
      backgroundColor: "#E0F2FE",
      partners: computed(() => useStore().getters["data/portfolios"]),
      investments: computed(() => useStore().getters["data/investments"]),
      employees: computed(() => useStore().getters["data/employees"]),
      totalValue: computed(() => useStore().getters["data/totalValue"]),

      displayInvestments: 0,
      displayEmployees: 0,
      displayTotalValue: 0,
    });

    const style = reactive({
      backgroundColor: computed(() => ({ backgroundColor: state.backgroundColor })),
    });

    const setBackgroundColor = (triggerPoint: number, effectRange: number, startColor: string, endColor: string) => {
      if (windowManager.offsetTop() < triggerPoint) {
        state.backgroundColor = startColor;
      } else if (triggerPoint <= windowManager.offsetTop() && windowManager.offsetTop() <= triggerPoint + effectRange) {
        const percent = (windowManager.offsetTop() - triggerPoint) / effectRange;
        if (0 <= percent && percent <= 1) {
          state.backgroundColor = transColor(startColor, endColor, percent);
        }
      } else {
        state.backgroundColor = endColor;
      }
    };

    const formatNumber = (numberString: string | number, perfix: string = "") => {
      const number = Number(String(numberString).replace(/[^0-9.]/g, ""));
      if (number < 10000) {
        return number.toLocaleString("en-US");
      }
      let si = [
        { v: 1e6, s: "M" },
        { v: 1e9, s: "B" },
        { v: 1e12, s: "T" },
        { v: 1e15, s: "P" },
        { v: 1e18, s: "E" },
      ];
      let index;
      for (index = si.length - 1; index > 0; index--) {
        if (number >= si[index].v) {
          break;
        }
      }
      return perfix + (number / si[index].v).toFixed(0).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
    };

    const lastTickCount = 80;
    let tickCount = 0;

    const tick = () => {
      if (tickCount <= lastTickCount) {
        updateTick(tickCount / lastTickCount);
        setTimeout(() => {
          tickCount++;
          tick();
        }, 10);
      }
    };

    const updateTick = (percent: number) => {
      state.displayInvestments = Math.ceil(state.investments * percent);
      state.displayEmployees = Math.ceil(state.employees * percent);
      state.displayTotalValue = Math.ceil(state.totalValue * percent);
    };

    onMounted(() => {
      tick();
    });

    watch(
      () => windowManager.offsetTop(),
      () => {
        setBackgroundColor(getElementBound("top", true) / 2, 400, "#E0F2FE", "#FFFFFF");
      }
    );

    const onClick = (id: string, params: any) => {
      if (id == "go") {
        const element = document.querySelector<HTMLElement>(params);
        if (element) {
          scrollTo({
            behavior: "smooth",
            top: element?.getClientRects().item(0)?.top,
          });
        }
      }
    };

    return {
      state,
      style,
      formatNumber,
      onClick,
    };
  },
});
