import { Module } from "vuex";
import { RootState } from "../index";

export interface GlobalModule {
  isWhiteHeader: boolean;
  isWhiteFooter: boolean;
}

export const globalModul: Module<GlobalModule, RootState> = {
  namespaced: true,
  state: () => ({
    isWhiteHeader: true,
    isWhiteFooter: false,
  }),
  getters: {
    isWhiteHeader: (state) => {
      return state.isWhiteHeader;
    },
    isWhiteFooter: (state) => {
      return state.isWhiteFooter;
    },
  },
  mutations: {
    setWhiteHeader: (state) => {
      state.isWhiteHeader = true;
    },
    clearWhiteHeader: (state) => {
      state.isWhiteHeader = false;
    },
    setWhiteFooter: (state) => {
      state.isWhiteFooter = true;
    },
    clearWhiteFooter: (state) => {
      state.isWhiteFooter = false;
    },
  },
  actions: {
    setWhiteHeader(context) {
      context.commit("setWhiteHeader");
    },
    clearWhiteHeader(context) {
      context.commit("clearWhiteHeader");
    },
    setWhiteFooter(context) {
      context.commit("setWhiteFooter");
    },
    clearWhiteFooter(context) {
      context.commit("clearWhiteFooter");
    },
  },
};
