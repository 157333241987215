
import { defineComponent, reactive } from "vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  components: {
    Header,
    Footer,
  },
  setup() {
    const state = reactive({});

    return {
      state,
    };
  },
});
