import { Portfolio, Story } from "@/types";
import { Module } from "vuex";
import { RootState } from "../index";

export interface DataModule {
  stories: Story[];
  portfolios: Portfolio[];
  investments: number;
  employees: number;
  totalValue: number;
}

export const dataModule: Module<DataModule, RootState> = {
  namespaced: true,
  state: () => ({
    stories: [
      {
        type: "News",
        backgroundImagePath: require("@/assets/images/patterns/image-01.jpg"),
        backgroundColor: "linear-gradient(0deg, rgba(34, 34, 34, 0.5), rgba(34, 34, 34, 0.5))",
        from: "Forbes",
        fromStyle: "background:rgba(0, 0, 0, 0.08);color:#ffffff;",
        title: "Animoca Brands hits $5.9 billion valuation as crypto winter deepens",
        titleStyle: "color:#ffffff;",
        typeStyle: "color:#FFFFFF",
        link: "https://www.forbes.com/sites/zinnialee/2022/07/12/animoca-brands-hits-59-billion-valuation-as-crypto-winter-deepens/?sh=705b2c42469d",
      },
      {
        type: "News",
        backgroundImagePath: require("@/assets/images/patterns/image-pattern-06.jpg"),
        backgroundColor: "rgba(0,0,0,0)",
        from: "Global Newswire",
        fromStyle: "rgba(22, 22, 22, 0.08);color:#161616;",
        title: "Fellaz Launched NFT ticket for Ultra Abu Dhabi 2023 powered by BNB Chain",
        titleStyle: "color:#161616;",
        link: "https://www.globenewswire.com/en/news-release/2023/03/17/2629378/0/en/Fellaz-s-NFT-Ticket-for-Ultra-Abu-Dhabi-2023-Powered-By-BNB-Chain-Sets-New-Standard-for-Web3-Fan-Entertainment.html",
      },
      {
        type: "Perspectives",
        backgroundImagePath: require("@/assets/images/patterns/image-pattern-05.jpg"),
        backgroundColor: "rgba(0,0,0,0)",
        from: "Hype Labs",
        fromStyle: "background:rgba(0, 0, 0, 0.08);color:#ffffff;",
        title: "Black Combat, a rising MMA organization, wins against DEEP, Japan’s #2 brand",
        titleStyle: "color:#ffffff;",
        typeStyle: "color:#FFFFFF",
        link: "https://www.globenewswire.com/en/news-release/2023/03/17/2629378/0/en/Fellaz-s-NFT-Ticket-for-Ultra-Abu-Dhabi-2023-Powered-By-BNB-Chain-Sets-New-Standard-for-Web3-Fan-Entertainment.html",
      },
      {
        type: "News",
        backgroundImagePath: require("@/assets/images/patterns/image-pattern-01.jpg"),
        backgroundColor: "rgba(0,0,0,0)",
        from: "Global Newswire",
        fromStyle: "background:rgba(0, 0, 0, 0.08);color:#ffffff;",
        title: "Innocus Global Group announces investment in Fingerlabs, the blockchain company owned by FSN",
        titleStyle: "color:#ffffff;",
        typeStyle: "color:#FFFFFF",
        link: "https://www.globenewswire.com/en/news-release/2022/12/09/2571038/0/en/Innocus-Global-Group-Announces-Investment-in-Fingerlabs-the-Blockchain-Company-Owned-by-FSN-a-South-Korean-Digital-Marketing-Powerhouse.html",
      },
      {
        type: "News",
        backgroundImagePath: require("@/assets/images/patterns/image-perspective-01.jpg"),
        backgroundColor: "rgba(0,0,0,0)",
        from: "EDM.com",
        fromStyle: "rgba(22, 22, 22, 0.08);color:#161616;",
        title: "Marshmello, Martin Garrix, Nicole Moudaber and more announced for Ultra Korea 2022",
        titleStyle: "color:#161616;",
        link: "https://edm.com/events/ultra-korea-2022-lineup-phase-one",
      },
      {
        type: "News",
        backgroundImagePath: require("@/assets/images/patterns/image-pattern-04.jpg"),
        backgroundColor: "rgba(0,0,0,0)",
        from: "Cointelegraph",
        fromStyle: "rgba(22, 22, 22, 0.08);color:#161616;",
        title: "Fellaz paves the way for Web3-entertainment ecosystems",
        titleStyle: "color:#161616;",
        link: "https://cointelegraph.com/press-releases/fellaz-paves-the-way-for-web3-entertainment-platforms-for-major-k-pop-artists-influencers-and-fans",
      },
      {
        type: "News",
        backgroundImagePath: require("@/assets/images/patterns/image-pattern-07.jpg"),
        backgroundColor: "rgba(0,0,0,0)",
        from: "Bloomberg",
        fromStyle: "background:rgba(0, 0, 0, 0.08);color:#ffffff;",
        title: "OG launches 1st phase of its one-stop community exchange, OG Playground",
        titleStyle: "color:#ffffff;",
        typeStyle: "color:#FFFFFF",
        link: "https://www.bloomberg.com/press-releases/2023-04-03/og-launches-the-first-phase-of-its-one-stop-community-exchange-og-playground",
      },
    ],
    portfolios: [
      { alt: "", imagePath: require("@/assets/images/image-company-animoca.png") },
      { alt: "", imagePath: require("@/assets/images/image-company-og.png") },
      { alt: "", imagePath: require("@/assets/images/image-company-fellaz.png") },
      { alt: "", imagePath: require("@/assets/images/image-company-innocus.png") },
      { alt: "", imagePath: require("@/assets/images/image-company-umf.png") },
      { alt: "", imagePath: require("@/assets/images/image-company-strike.png") },
      { alt: "", imagePath: require("@/assets/images/image-company-flfi.png") },
      { alt: "", imagePath: require("@/assets/images/image-company-polestar.png") },
      { alt: "", imagePath: require("@/assets/images/image-company-blackcombat.png") },
      { alt: "", imagePath: require("@/assets/images/image-company-paragon.png") },
      { alt: "", imagePath: require("@/assets/images/image-company-ycs.png") },
      { alt: "", imagePath: require("@/assets/images/image-company-dogo.png") },
    ],
    investments: 13,
    employees: 3814,
    totalValue: 73000000000,
  }),
  getters: {
    stories(state) {
      return state.stories;
    },
    portfolios(state) {
      return state.portfolios;
    },
    investments(state) {
      return state.investments;
    },
    employees(state) {
      return state.employees;
    },
    totalValue(state) {
      return state.totalValue;
    },
  },
  mutations: {},
  actions: {},
};
