
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
// import { Ball, World } from "@/libs/CanvasCircleCollisions";
import { Ball, World } from "@/libs/ElementCircleCollisions";
import { useWindowManager } from "@/libs/WindowManager";

export default defineComponent({
  setup() {
    const state = reactive({
      balls: [
        { x: 578, y: 200 + 40, dx: 0, dy: 0, color: "#C3F0C8", r: 100, label: "Data Science", labelColor: "#161616", labelFont: "18px 'Robert', sans-serif" },
        { x: 80, y: 102 + 40, dx: 0, dy: 0, color: "#CBE9FD", r: 80, label: "Business", labelColor: "#161616", labelFont: "18px 'Robert', sans-serif" },
        { x: 466, y: 126 + 40, dx: 0, dy: 0, color: "#384DBF", r: 70, label: "HR & Legal", labelColor: "#FFFFFF", labelFont: "18px 'Robert', sans-serif" },
        {
          x: 231,
          y: 128 + 40,
          dx: 0,
          dy: 0,
          color: "#E65D4C",
          r: 120,
          label: "Visual & UX Design",
          labelColor: "#FFFFFF",
          labelFont: "18px 'Robert', sans-serif",
        },
        { x: 725, y: 144 + 40, dx: 0, dy: 0, color: "#E7ED53", r: 90, label: "Go-To-Market", labelColor: "#161616", labelFont: "18px 'Robert', sans-serif" },
        { x: 1, y: 264 + 40, dx: 0, dy: 0, color: "#FACB71", r: 140, label: "Engineering", labelColor: "#161616", labelFont: "18px 'Robert', sans-serif" },
        {
          x: 485,
          y: 244 + 40,
          dx: 0,
          dy: 0,
          color: "#FFC9CE",
          r: 150,
          label: "Market Research",
          labelColor: "#161616",
          labelFont: "18px 'Robert', sans-serif",
        },
        { x: 787, y: 304 + 40, dx: 0, dy: 0, color: "#006450", r: 120, label: "Finance", labelColor: "#FFFFFF", labelFont: "18px 'Robert', sans-serif" },
        { x: 311, y: 364 + 40, dx: 0, dy: 0, color: "#6740CF", r: 90, label: "Operations", labelColor: "#FFFFFF", labelFont: "18px 'Robert', sans-serif" },
        { x: 454, y: 44 + 40, dx: 0, dy: 0, color: "#FACB71", r: 25, opacity: 0.5 },
        { x: 697, y: 227 + 40, dx: 0, dy: 0, color: "#CBE9FD", r: 27, opacity: 0.5 },
        { x: 285, y: 508 + 40, dx: 0, dy: 0, color: "#EAF6EB", r: 36, opacity: 0.5 },
      ],
      mobileBalls: [
        { x: 0, y: 0 + 40, dx: 0, dy: 0, color: "#006450", r: 80, label: "Finance", labelColor: "#FFFFFF", labelFont: "18px 'Robert', sans-serif" },
        { x: 378, y: 0 + 40, dx: 0, dy: 0, color: "#C3F0C8", r: 100, label: "Data Science", labelColor: "#161616", labelFont: "18px 'Robert', sans-serif" },

        { x: 225, y: 0 + 40, dx: 0, dy: 0, color: "#E7ED53", r: 75, label: "Go-To-Market", labelColor: "#161616", labelFont: "18px 'Robert', sans-serif" },
        { x: 400, y: 92 + 40, dx: 0, dy: 0, color: "#CBE9FD", r: 65, label: "Business", labelColor: "#161616", labelFont: "18px 'Robert', sans-serif" },
        { x: 366, y: 126 + 40, dx: 0, dy: 0, color: "#384DBF", r: 60, label: "HR & Legal", labelColor: "#FFFFFF", labelFont: "18px 'Robert', sans-serif" },

        {
          x: 231,
          y: 128 + 40,
          dx: 0,
          dy: 0,
          color: "#E65D4C",
          r: 100,
          label: "Visual & UX Design",
          labelColor: "#FFFFFF",
          labelFont: "18px 'Robert', sans-serif",
        },

        { x: 1, y: 264 + 40, dx: 0, dy: 0, color: "#FACB71", r: 90, label: "Engineering", labelColor: "#161616", labelFont: "18px 'Robert', sans-serif" },
        { x: 485, y: 244 + 40, dx: 0, dy: 0, color: "#FFC9CE", r: 90, label: "Market Research", labelColor: "#161616", labelFont: "18px 'Robert', sans-serif" },

        { x: 311, y: 364 + 40, dx: 0, dy: 0, color: "#6740CF", r: 70, label: "Operations", labelColor: "#FFFFFF", labelFont: "18px 'Robert', sans-serif" },
        { x: 454, y: 44 + 40, dx: 0, dy: 0, color: "#FACB71", r: 35, opacity: 0.5 },
        { x: 697, y: 227 + 40, dx: 0, dy: 0, color: "#CBE9FD", r: 35, opacity: 0.5 },
        { x: 285, y: 508 + 40, dx: 0, dy: 0, color: "#EAF6EB", r: 35, opacity: 0.5 },
      ],
    });
    const canvas = ref<HTMLCanvasElement>();
    const pool = ref<HTMLDivElement>();

    onMounted(() => {
      if (pool.value) {
        createWorld();
      }
    });

    const createWorld = () => {
      if (pool.value) {
        if (pool.value.hasChildNodes()) {
          pool.value.innerHTML = "";
        }
        const world = new World(pool.value);

        if (useWindowManager().isMobile()) {
          state.mobileBalls.forEach((ball) => {
            world.addBall(new Ball(ball));
          });
        } else {
          state.balls.forEach((ball) => {
            world.addBall(new Ball(ball));
          });
        }

        world.start();
      }
    };

    watch(
      () => useWindowManager().isDesktop(),
      (n, p) => {
        if (n != p) {
          createWorld();
        }
      }
    );

    return {
      state,
      canvas,
      pool,
    };
  },
});
