import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import DefaultLayout from "@/layouts/DefaultLayout.vue";

import AboutPage from "@/pages/AboutPage.vue";
import StudioPage from "@/pages/StudioPage.vue";
import VenturesPage from "@/pages/VenturesPage.vue";
import StoriesPage from "@/pages/StoriesPage.vue";
import CareersPage from "@/pages/CareersPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: DefaultLayout,
    children: [
      {
        name: "Main",
        path: "/",
        component: AboutPage,
      },
      {
        name: "About",
        path: "/about",
        component: AboutPage,
      },
      {
        name: "Studio",
        path: "/studio",
        component: StudioPage,
      },
      {
        name: "Ventrues",
        path: "/ventures",
        component: VenturesPage,
      },
      {
        name: "Stories",
        path: "/stories",
        component: StoriesPage,
      },
      {
        name: "Careers",
        path: "/careers",
        component: CareersPage,
      },
      {
        name: "NotFoundPage",
        path: ":pathMatch(.*)*",
        redirect: "/",
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
